<template>
  <div class="goodsType ">
    <div class="content ">
      <div class="numContent board_box">
        <div class="single">
          <div class="top">
            <img src="@/assets/img/statisticalReport/sellNum.png" class="pic" alt="位元灵感"/>
            <div class="numTitle">销售数量</div>
          </div>
          <div class="bottom" v-increase="total.saleCount"></div>
        </div>
        <div class="single">
          <div class="top">
            <img
              src="@/assets/img/statisticalReport/totalNum.png"
              class="pic"
              alt="位元灵感"
            />
            <div class="numTitle">销售金额</div>
          </div>
          <div class="bottom" v-increase="total.salePrice">{{ total.salePrice }}</div>
        </div>
        <div class="single">
          <div class="top">
            <img src="@/assets/img/statisticalReport/price.png" class="pic" alt="位元灵感"/>
            <div class="numTitle">成本金额</div>
          </div>
          <div class="bottom" v-increase="total.costPrice">{{ total.costPrice }}</div>
        </div>
        <div class="single">
          <div class="top">
            <img src="@/assets/img/statisticalReport/profit.png" class="pic" alt="位元灵感"/>
            <div class="numTitle">毛利金额</div>
          </div>
          <div class="bottom" v-increase="total.profitPrice">{{ total.profitPrice }}</div>
        </div>
      </div>
      <div class="eChartBox board_box">
        <!--        <div :id="eChart" :style="{height: '440px' , width: '100%'}"></div>-->
        <div :id="eChart" :style="{height: 'calc(100vh - 320px)' , width: '100%'}"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import * as echarts from "echarts";
import {getCurrentInstance, nextTick, onMounted, reactive, ref, toRefs, watch,} from "vue";
import {debounce} from 'lodash'
import {useEventListener} from '@utils/tool/event'
import {reportWebProfit} from "@utils/api/census"
import moment from "moment";

const props = defineProps({
  formData: {
    type: Object,
    default: () => {},
  },
});
const emit = defineEmits(["changeList"])
const total = reactive({
  saleCount: 0,
  salePrice: 0,
  costPrice: 0,
  profitPrice: 0,
})

const { proxy } = getCurrentInstance();
const {formData} = toRefs(props);
const eChart = ref("eChart" + Date.now() + Math.random());
const tableData = ref([]);
let myChart = null;
const moneyText = ref('')
const showMoneyText = ref('')
const initEchart = (list = []) => {
  moneyText.value = ''
  //销售成本
  let sellList = [];
  //毛利金额
  let profitList = [];
  let indexList = [];
  //循环list 判断里面的最大值是否超过10000
  if (list.findIndex(item => item.salePrice > 10000000 || item.profitPrice > 10000000) !== -1) {
    moneyText.value = ' 万元'
  } else {
    moneyText.value = ' 元'
  }


  list.forEach((item) => {
    let sell = proxy.$filters.toYuan(item.salePrice, moneyText.value === ' 万元' ? '万元' : '元')
    let profit = proxy.$filters.toYuan(item.profitPrice, moneyText.value === ' 万元' ? '万元' : '元')
    sellList.push({
      trueValue:item.salePrice,
      value:sell
      });
      profitList.push({
        trueValue:item.profitPrice,
        value:profit
      });
    indexList.push(item.index)
  });
  emit('changeList', list)
  let chartDom = document.getElementById(eChart.value);
  echarts.dispose(chartDom)
  myChart = echarts.init(chartDom);
  let option;
  option = {
    title: {
      show: indexList.length === 0,   // 没数据才显示
      extStyle: {
        color: "grey",
        fontSize: 20
      },
      text: "暂无数据",
      left: "center",
      top: "center"
    },
    xAxis: {
      type: "category",
      //横向坐标
      data: indexList,
      textBorderType: [5, 10],
      textBorderDashOffset: 0,
      show: indexList.length !== 0,
      axisLabel: {
        color: '#AAAAB2',
        interval: 0,
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: '#AAAAB2',
        }
      },
    },
    yAxis: {
      type: "value",
      borderWidth: 100,
      splitLine: {
        lineStyle: {
          type: [5, 8],
          color: "#E8EAED"
        }
      },
      axisLabel: {
      color: '#444343',
        formatter: function (value) {
          // value大于1000时除以1000并拼接k，小于1000按原格式显示
          if (value >= 1000 || value <= -1000) {
            value = value / 1000 + 'k';
          }
          return value + moneyText.value;
        },
      },
    },
    legend: {
      data: [
        {
          name: `销售金额`,
          icon: 'roundRect',

        },
        {
          name: `毛利金额`,
          icon: 'roundRect',

        },],
      left: 10,
      itemWidth: 14,
      itemHeight: 14,
      itemGap: 60,
      itemStyle: {
        borderWidth: 8,
        borderCap: "square",
      },
    },
    tooltip: {
      trigger: "axis",
      formatter: (data = []) => {
        const index1 = data.findIndex(item => item.componentIndex === 0); //销售金额
        const index2 = data.findIndex(item => item.componentIndex === 1); //毛利金额
        const num1 = index1 !== -1 ? proxy.$filters.toYuan(data[index1].data.trueValue) : null;
        const num2 = index2 !== -1 ? proxy.$filters.toYuan(data[index2].data.trueValue) : null;
        const text1 = index1 !== -1 ? data[index1].seriesName : null;
        const text2 = index2 !== -1 ? data[index2].seriesName : null;
        if (num1 !== null && num2 !== null) {
          return `<div style='color: #161829;font-size: 18px;text-align:center'>${num1}</div><div style='color: #AAAAB2;font-size: 12px;margin-top:2px'>${text1}</div><div style='color: #161829;font-size: 18px;margin-top:6px;text-align:center'>${num2}</div><div style='color: #AAAAB2;font-size: 12px;margin-top:2px'>${text2}</div>`;
        } else if (num1 !== null) {
          return `<div style='color: #161829;font-size: 18px;text-align:center'>${num1}</div><div style='color: #AAAAB2;font-size: 12px;margin-top:2px'>${text1}</div>`;
        } else if (num2 !== null) {
          return `<div style='color: #161829;font-size: 18px;text-align:center'>${num2}</div><div style='color: #AAAAB2;font-size: 12px;margin-top:2px'>${text2}</div>`;
        } else {
          return null;
        }
      } ,
    },
    grid: {
      left: '5%',
      right: '5%',
    },
    dataZoom: [
      {
        // 设置滚动条的隐藏与显示
        show: true,
        // 设置滚动条类型
        type: "slider",
        // 设置背景颜色
        backgroundColor: "rgba(0,0,0,0)",
        // 设置选中范围的填充颜色
        fillerColor: "rgba(0,0,0,0)",
        // 设置边框颜色
        borderColor: "rgba(0,0,0,0)",
        moveHandleSize: 6,
        moveHandleStyle: {
          color: "#E8EAED",
          borderColor: "#E8EAED",
          borderCap: "round",
          borderJoin: "round",
          borderDashOffset: 20
        },
        // 是否显示detail，即拖拽时候显示详细数值信息
        showDetail: false,
        // 数据窗口范围的起始数值
        startValue: 0,
        // 数据窗口范围的结束数值（一页显示多少条数据）
        endValue: 15,
        // empty：当前数据窗口外的数据，被设置为空。
        // 即不会影响其他轴的数据范围
        filterMode: "empty",
        // 设置滚动条宽度，相对于盒子宽度
        width: "90%",
        // 设置滚动条高度
        height: 8,
        // 设置滚动条显示位置
        left: "center",
        // 是否锁定选择区域（或叫做数据窗口）的大小
        zoomLoxk: true,
        // 控制手柄的尺寸
        handleSize: 0,
        // dataZoom-slider组件离容器下侧的距离
        bottom: 30,
      },
      {
        // 没有下面这块的话，只能拖动滚动条，
        // 鼠标滚轮在区域内不能控制外部滚动条
        type: "inside",
        // 滚轮是否触发缩放
        zoomOnMouseWheel: false,
        // 鼠标滚轮触发滚动
        moveOnMouseMove: true,
        moveOnMouseWheel: true,
      },
    ],
    series: [
      {
        name: `销售金额`,
        //销售金额折线图
        data: sellList,
        type: "line",
        smooth: true,
        itemStyle: {
          color: "#48CB9F",
        },
         emphasis:{
          focus:'self',
          scale:3
        },
         lineStyle:{
           width: 2
        }
      },
        {
          name: `毛利金额`,
          //毛利金额折线图
          data: profitList,
        type: "line",
        smooth: true,
        itemStyle: {
          color: "#3859FF",
        },
        emphasis:{
          focus:'self',
          scale:3
        },
        lineStyle:{
          width: 2
        }
      },
      {
        name: `销售金额`,
        //销售金额柱状图
        data: sellList,
        type: "bar",
        barGap: "-100%",
        z: "-1",
        barWidth: 20,
        itemStyle: {
          color: "rgba(72, 203, 159,0.2)",
        },
      },
      {
        //毛利金额柱状图
        name: `毛利金额`,
        data: profitList,
        type: "bar",
        barGap: "-100%",
        z: "-1",
        barWidth: 20,
        itemStyle: {
          color: "rgba(56, 89, 255,0.2)",
        },
      },

    ],
  };

  option && myChart.setOption(option);
};

onMounted(() => {
  nextTick(() => {
    getData();
  });
});

// 重置eChart大小
useEventListener(window, 'resize', debounce(() => {
  myChart.resize()
}, 100))

const getData = () => {
  let obj = {
    stockId: formData.value.stockId || 0,
    beginTime: formData.value.time && formData.value.time[0] ? moment(formData.value.time[0]).format('YYYYMMDD') : 0,
    endTime: formData.value.time && formData.value.time[1] ? moment(formData.value.time[1]).format('YYYYMMDD') : 0,
    timeType: formData.value.timeType || 0,
  }
  reportWebProfit(obj).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    if (res.data.total) {
      total.costPrice = proxy.$filters.toYuan(res.data.total.costPrice)
      total.profitPrice = proxy.$filters.toYuan(res.data.total.profitPrice)
      total.saleCount = res.data.total.saleCount
      total.salePrice = proxy.$filters.toYuan(res.data.total.salePrice)
    }
    initEchart(res.data?.list || [])
  })
}
watch(
  () => formData.value,
    () => {
      getData()
  },
  {
    deep: true,
  }
);
</script>
<script>
export default {
  name: "goodsType",
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.goodsType {
  width: 100%;
  height: 100%;
  .content {
    width: 100%;
    .eChartBox {
      padding-bottom: 0;
    }
    .numContent {
      display: flex;
      margin-bottom: 16px;
      .single {
        flex: 1;
        width: 25%;
        .top {
          display: flex;
          align-items: center;
          .pic {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
          .numTitle {
            color: #737480;
            font-size: 14px;
            @include textOverflow(100%);
            max-width: calc(100% - 32px);
            width: calc(100% - 32px);
            flex: none;
          }
        }
        .bottom {
          margin-top: 12px;
          color: #161829;
          font-size: 24px;
          font-weight: bold;
          @include textOverflow(100%);
          padding-left: 32px;
          box-sizing: border-box;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
